<template>
  <div>
    <!-- address section start -->
    <section class="contact-page policy-content section-b-space">
      <div class="container">
        <!-- <div class="row">
          <div class="col-md-3 ml-auto my-4 mr-n5">
            <label class="font-weight-bold"> Select Language </label>
            <b-form-select v-model="selected" :options="options"></b-form-select>
          </div>
        </div> -->
        <div class="row">
          <div class="col-sm-12 text-justify" v-if="selected === 'ENGLISH'">
            <h2 class="text-left mb-3 mt-4">Teka Terms of Use</h2>
            <h5>TERMS & CONDITIONS</h5>
            <p>
              These terms of use (Terms) constitute a legally binding agreement
              between you and the Company regarding your use of the web site
              i.e. www.teka.net.in (“TEKA”) and it’s mobile applications offered
              by the company including but not limited to delivery of content
              via the Site, any mobile or internet connected device or otherwise
              ("the Service"). By accessing the Site or Service and/or by
              clicking "I agree", you agree to be bound by these Terms. “User”
              or “You” means any individual who get to or avail this site of the
              Company for the reason of facilitating, distributing, sharing,
              executing, showing or uploading data or sees and incorporates
              other people together partaking in utilizing the site of the
              Company. You hereby represent and warrant to the Company that you
              are at least eighteen (18) years of age or above and are capable
              of entering, performing and adhering to these Terms and that you
              agree to be bound by the following terms and conditions. While
              individuals under the age of 18 may utilize TEKA services, they
              shall do so only with the involvement & guidance of their parents
              and / or legal guardians, under such Parent /Legal guardian’s
              registered account. You agree to register prior to uploading any
              content and / or comment and any other use or services of this
              site and provide your details including but not limited to
              complete name, age, email address, residential address, place, and
              contact number.
            </p>
            <p>
              TEKA may add to or change or update these Terms of Use, from time
              to time entirely at its own discretion. You're responsible for
              checking these Terms of Use occasionally to stay in compliance
              with these terms. Your utilization of a Site after any
              modification to the Terms of Use will constitute your affirmation
              of these terms and you also assent to be bound by any such
              changes/corrections.
            </p>
            <h5>MODIFICATIONS</h5>
            <p>
              Vedhaank Digital Pvt Ltd (“VAPL”) reserves the right to suspend /
              cancel, or discontinue any or all channels, products or service at
              any time without notice, make changes and modifications in any or
              the greater part of the content, products and services contained
              on the site without earlier notice.
            </p>
            <h5>CHARGES</h5>
            <p>
              VAPL may or may not charge subscription and / or membership fees
              from a user, by giving reasonable prior notice, in respect of any
              product, service or any other aspect of this Site, it reserves all
              the rights to modify this agreement.
            </p>
            <h5>COPYRIGHT AND TRADEMARKS</h5>
            <p>
              Unless otherwise stated, copyright and all intellectual property
              rights in all material presented on the site and mobile apps
              (including but not limited to text, audio, video or graphical
              images), trademarks and logos appearing on this site are the
              property of Vedhaank Digital Pvt Ltd, its parent, affiliates and
              associates and are protected under applicable Indian laws. You
              agree not to use any architecture techniques to enclose any
              trademark or logo or other proprietary information of VAPL; or
              remove, conceal or blot out any copyright or other proprietary
              notice or any credit-line or date-line on other mark or source
              identifier included on the Site / Service, including without
              limitation, the size, colour, location or style of all proprietary
              marks. Any encroachment will be vivaciously protected and sought
              after without limitations degree allowed by law.
            </p>
            <h5>CONSTRAINED AUTHORIZATION TO DUPLICATE</h5>
            <p>
              VAPL gives you permission to let you access and make personal use
              of the Site and You agree not to, directly or indirectly download
              or modify / alter / change / amend / vary / transform / revise /
              translate / copy / publish / distribute or otherwise disseminate
              any content on VAPL's Site / Service, or any portion of it; or
              remove or fail to display any promotional taglines included in the
              Site / Service either directly or indirectly, except with the
              express consent of VAPL. In any case, you'll print or download
              extricates from these pages for your individual / person,
              non-commercial utilize as it were. You must not hold any
              duplicates of these pages spared to disk or to any other capacity
              medium but for the purposes of utilizing the same for ensuing
              seeing purposes or to print extricates for individual / person
              utilize.
            </p>
            <p>
              VAPL precludes you from any endeavors to exchange or put to
              commercial utilize any portion of the Site; any collection and
              utilize of any product listings, descriptions, or costs; any
              subsidiary utilize of the Site or its substance; any downloading
              or replicating of account data for the good thing about any other
              merchant; any renting, leasing, or otherwise transferring rights
              to the Site / Service; showing the title, symbol, trademark or
              other identifier of another individual in such a way as to deliver
              the viewer the impression that such other individual is a
              publisher or distributor of the Service on the Site, or any data
              gathering or extraction tools; or any use of meta tags. You will
              not make a database in electronic or organized manual frame by
              routinely or efficiently downloading and putting away all or any
              portion of the pages from this site. No portion of the Site may be
              duplicated or transmitted to or put away in any other web
              location, nor may any of its pages or portion thereof be spread in
              any electronic or non-electronic frame, nor included in any open
              or private electronic recovery framework or benefit without
              earlier composed consent.
            </p>
            <h5>SHARING OF PRIVATE DATA</h5>
            <p>
              TEKA is designed to help you find the best of restaurants,
              nightlife, events and movies around you and your city. In order to
              do this, TEKA requires the use of such information including, but
              not limited to, your email, password and or other information that
              was used while Signing Up with the services. TEKA offers Sign Up
              process with our own sign-up system and other platforms such as
              Facebook and Google+.
            </p>
            <p>
              TEKA uses Facebook Connect and Google+ platforms as a medium for
              signing up only. The user data collected by TEKA shall only be
              through the above mediums which will be provided by the intended
              user through their Facebook or Google+ profile, no other data
              shall be collected/used by TEKA apart from those that was
              permitted during Sign Up process.
            </p>
            <p>
              However, kindly note that the manner in which Facebook and Google+
              uses, stores and discloses your information is governed solely by
              its policies, and VAPL/TEKA bears no liabilities/responsibility
              for its privacy practices and/or other actions of any third-party
              site or service that may be enabled within the Service.
            </p>
            <p>
              For certain services such as email, personal web pages, contests
              and shopping, signing up by the visitor is required. To sign up
              for these services you have to open an account by completing the
              signing up process (i.e., by providing us with current, complete
              and accurate information as prompted by the applicable
              registration form). You also will choose a password. You are
              entirely responsible for maintaining the confidentiality of your
              password and account. By Signing Up, you agree to the following
              terms in addition to any other specific terms which shall be
              posted at an appropriate location of the Site. Each sign up is for
              a single individual user only. To access these services, you will
              be asked to enter your individual Email Address and Password, as
              chosen by you during your signing up. Therefore, we do not permit
              any of the following: -
            </p>
            <p>Any other person sharing your account and Password;</p>
            <p>
              Any part of the Site being cached in proxy servers and accessed by
              individuals who have not registered with TEKA as users of the
              Site; or
            </p>
            <p>
              Access through a single account and Password being made available
              to multiple users on a network.
            </p>
            <p>
              If TEKA reasonably believe that an account and Password is being
              used / misused in any manner, TEKA shall reserve the right to
              cancel access rights immediately without notice, and block access
              to all users from that IP address.
            </p>
            <p>
              Besides, you're totally capable for any and all activities that
              happen beneath your account. You concur to inform TEKA quickly of
              any unauthorized utilize of your account or any other breach of
              security. TEKA will not be obligated for any misfortune that you
              simply may incur as a result of somebody else utilizing your
              secret word or account. In any case, you may be held at risk for
              losses caused by VAPL/TEKA or another party due to somebody else
              utilizing your account or password.
            </p>
            <h5>USE OF PROFILE INFORMATION</h5>
            <p>
              We may utilize the individual data to supply the services you ask.
              In any case, to the degree we utilize your individual data to
              market to you, we are going moreover give you the ability to
              opt-out of such uses. The individual data might be utilized by us
              to resolve debate; troubleshoot issues; help/promote a secure
              benefit; measure consumer interest in our products and services,
              inform you approximately online and offline offers, items,
              administrations, and upgrades; customize your experience; detect
              and secure us against blunder, fraud and other criminal action;
              enforce our terms and conditions; and as something else depicted
              to you at the time of collection. In our endeavors to ceaselessly
              progress our items and administrations, we collect and analyze
              statistic and profile information almost our users' action on our
              versatile application. We may every so often inquire you to lock
              in in real or intrigued based overviews. These studies may ask you
              for more data around your intrigued and demographic data on our
              mobile application.
            </p>
            <h5>LOCATION SHARING</h5>
            <p>
              TEKA is about recommending you restaurants / places around you and
              your city and in order to do that we would need to know your
              location. Whenever you open and interact with TEKA app on your
              mobile, we use the location information from your mobile device or
              browser (latitude and longitude) to customize the experience
              around your location (i.e., showing you recommended places to eat,
              or the grocery stores). Your location information is NEVER shared
              with others. TEKA mobile app also permits you to choose a region
              of your choice or let the app decide your current area by tapping
              on your area title on Area Alter screen. It permits us to tell you
              where the proposals are based on the area that's decided by the
              gadget or your choice.
            </p>
            <h5>NO ILLEGAL OR DISALLOWED USE</h5>
            <p>
              As a condition of your use of the Services, you may not utilize
              the Services for any reason that's illegal or prohibited by these
              terms, conditions, and takes note. You'll not utilize the Services
              in any way that seem harm, cripple, overburden, or impede any of
              our servers, or the network(s) associated to any VAPL server, or
              interfere with any other party's utilize and delight of any
              Services. You will not endeavor to pick up unauthorized get to any
              Services, other accounts, computer systems or to any of the
              Services, through hacking, secret word mining or any other
              implies. You will not get or endeavor to get any materials or data
              through any implies not intentionally made available through the
              Services.
            </p>
            <h5>CONTENT POSTED AT TEKA</h5>
            <p>
              All data, information, content, software, music, sound, photos,
              design, video, messages or other materials ("Substance"), whether
              freely or secretly transmitted / posted, is the sole obligation of
              the individual from where such substance is started (the
              Originator). By Posting any material which contain pictures,
              photos, pictures or that are something else graphical in entirety
              or in portion ("Pictures"), you warrant and represent that (a)
              you're the copyright proprietor of such Pictures, or that the
              copyright proprietor of such Pictures has allowed you permission
              to utilize such Pictures or any content and/or pictures contained
              in such Pictures reliable with the way and purpose of your utilize
              and as otherwise allowed by these Terms of Use and the Services,
              (b) you've got the rights vital to give the licenses and
              sublicenses portrayed in these Terms of Use, and (c) that each
              individual delineated in such Pictures, in the event that any, has
              given assent to the utilize of the Pictures as set forward in
              these Terms of Use, including, by way of limitation, the
              distribution, public display and reproduction of such Images.
            </p>
            <p>
              You represent that you have valid rights and title in any and all
              Content/Images that you submit on the Site, that you have not
              infringed on any IPR belonging to any party and further that you
              will indemnify VAPL or its affiliates including TEKA for all
              claims arising out of any content that you post on the Site or its
              products.
            </p>
            <p>
              TEKA acknowledges no responsibility for the said Content /
              Pictures. In any case, you get it that all Content / Pictures
              posted by you gets to be the property of TEKA and you concur to
              grant/assign to TEKA, a non-exclusive, sovereignty free,
              never-ending, irreversible and sub-license able right to utilize,
              duplicate, adjust, adjust, distribute, translate, make subordinate
              works from, disperse, perform and show such Content / Pictures (in
              entirety or portion) around the world and/or to join it in other
              works in any frame, media, or technology presently known or
              afterward developed all through the world.
            </p>
            <h5>TRADE OF INFORMATION & OTHER COMMUNITY ADMINISTRATIONS</h5>
            <p>
              You represent that you are a responsible adult (18 years or above)
              and/or otherwise capable of entering into and performing legal
              agreements, and that you agree to be bound by the following terms
              and conditions. While individuals under the age of 18 may utilize
              the Service, they may do so only with the involvement of a parent
              or legal guardian, under such person´s account and otherwise
              subject to these Terms. In certain jurisdictions, the age of
              majority may be older than 18, in which case you must satisfy that
              age in order to become a member / utilize the Site or Service and
              you shall be solely responsible for all Content/Images that you
              upload post or otherwise transmit.
            </p>
            <p>
              TEKA tries to supply a web platform for different community
              interactions for people to interact and exchange views with each
              other. The content posted on such services is by general public
              hence the precision, keenness or quality of such content cannot be
              ensured. You get it that by using such services, you'll be exposed
              to objectionable matter. By utilizing the facility of
              review/rating and other community administrations you agree not to
              transfer, post, or something else transmit any matter or sees,
              which are, defamatory, damaging, inescapable, indecent, intrusive
              of another's privacy, hateful, or racially, ethnically or
              something else objectionable. You may also not enjoy in cyber
              stalking, become or make an annoyance for any guest / client of
              the Site. You may not send, yield, post, or something else
              transmit, material or messages that contain software virus, or any
              other files that are outlined to interrupt, destroy, and
              negatively influence in any way at all, any electronic gear in
              connection with the use of this Site or Mobile, or other user's
              ability to engage in real time exchanges.
            </p>
            <h5>PROHIBITED ACTIVITIES</h5>
            <p>
              You agree that You shall not host, display, upload, modify,
              publish, transmit, update or share any information on the Site,
              that it belongs to another person and to which you do not have any
              right to;
            </p>
            <p>
              grossly hurt, harassing, blasphemous defamatory, indecent,
              explicit, pedophilic, offensive, invasive of another's protection,
              hateful, or racially, ethnically objectionable, demonizing,
              relating or empowering money washing or gambling, or something
              else illegal in any way whatever;
            </p>
            <ul style="list-style: outside none none">
              <li>harm minors in any way;</li>
              <li>
                infringes any patent, trademark, copyright or other proprietary
                rights;
              </li>
              <li>violates any law for the time being in force;</li>
              <li>
                deceives or misleads the addressee about the origin of such
                messages or communicates any information which is grossly
                offensive or menacing in nature;
              </li>
              <li>impersonate another person</li>
              <li>
                contains software viruses or any other computer code, files or
                programs designed to interrupt, destroy or limit the
                functionality of any computer resource;
              </li>
            </ul>
            <p>
              Threatens the solidarity, integrity, defense, security or
              sovereignty of India, friendly relations with foreign states, or
              public order or causes incitement to the commission of any
              cognizable offence or prevents investigation of any offense or is
              insulting any other country.
            </p>
            <p>
              Any Content and or comment transferred by you, might be subject to
              relevant Indian laws and may be disabled, or and may be subject to
              examination under appropriate laws. Moreover, on the off chance
              that you're found to be in non-compliance with the laws and
              regulations, these terms, or the protection arrangement of the
              Site, the Company shall have the proper to promptly
              terminate/block your get to and utilization of the Site and the
              Company should have the right to quickly remove any non-compliant
              Content and or comment, transferred by you and should assist have
              the proper to require recourse to such remedies as would be
              available to the Company under the applicable laws.
            </p>
            <h5>Limitation of Liability</h5>
            <p>
              Any transactions relating to booking of table or reservations of
              goods or administrations not directly advertised by TEKA are to be
              settled inter-se between the parties to such exchange and all
              guarantees express or suggested of any kind, with respect to any
              matter relating thereto, counting without impediment the suggested
              warranties of merchantability, wellness for a specific reason, and
              non-infringement are repudiated by VAPL/TEKA. TEKA simply tries to
              supply a platform where you and other party may connect, trade
              data or carry out a reservation on such premise that are commonly
              concurred between you and the other party.
            </p>
            <p>
              TEKA might not be obligated for any misfortune that you just may
              cause, whereas making reservations or bookings of the third party.
              Beneath no circumstances might VAPL/TEKA be held mindful or at
              risk, in any way, for any content which in Legal opinion is
              defamatory, undermining, defamatory, obscene or offensive or
              irritates open sensibilities or ethics and might too not accept
              risk for any mistakes or exclusions in any substance, or for any
              misfortune or harm of any kind brought about as a result of the
              utilize of any substance posted or transferred on the Location, or
              any encroachment of another's rights, including intellectual
              property rights. You particularly concur that TEKA isn't mindful
              for any content sent using and/or included in TEKA’s
              site/mobile/service by any third party.
            </p>
            <h5>ENDING/CLOSING/TERMINATION OF ACCOUNT</h5>
            <p>VAPL reserves its right to:</p>
            <ol style="list-style-type: lower-alpha; padding-left: 15px">
              <li>Deny Service</li>
              <li>Limit, suspend, or end your account;</li>
              <li>Terminate this Agreement;</li>
              <li>Terminate or suspend your access to the Site;</li>
              <li>
                Refuse, move or remove for any reason any Content / Image that
                you submit on or through the Services;
              </li>
              <li>
                Deny, move, or remove any Content / Picture that's accessible on
                or through the Services;
              </li>
              <li>
                Set up common practices and limits concerning utilize of the
                Services at any time and,
              </li>
              <li>
                Expel or alter contents or cancel orders (entered by you) in its
                sole caution with or without cause, and with or without any
                earlier take note for any infringement of the Terms of Use.
              </li>
            </ol>
            <h5>USER CONDUCT AND OBLIGATIONS</h5>
            <p>
              You hereby agree and assure TEKA that the Site/Services shall be
              used for lawful purposes only and that you will not violate laws,
              regulations, ordinances or other such requirements of any
              applicable Central, State or local government or any other
              international laws. You further concur that you will not, through
              Site/Mobile/Service:
            </p>
            <p>
              post, distribute, or otherwise make available or transmit any
              software or other computer files that contain a virus trojan
              horses, time bombs, bots, botnets, malicious content, content
              theft, data manipulation, threats or any other harmful programs or
              elements or component;
            </p>
            <p>
              delete from the Site any legal notices, disclaimers, or
              restrictive takes note such as copyright or trademark images, or
              adjust any logos simply don't possess or have express
              authorization to modify;
            </p>
            <p>
              not utilize the Site / Mobile / Service in any way that might
              harm, debilitate, overburden, or impede and not to embrace any
              activity which is destructive or possibly destructive to any VAPL
              / TEKA's server, or the network(s), computer frameworks / asset
              associated to any TEKA's server, or meddled with any other party's
              utilize and satisfaction of the Site/Mobile/Service;
            </p>
            <p>
              obtain or endeavor to get any materials or data through any
              implies not intentionally made accessible through the
              Site/Mobile/Service;
            </p>
            <p>engage in any activity that causes / may harm minors; or</p>
            <p>perform any activity which is likely to cause such harm;</p>
            <p>
              impersonate any person or entity, including, but not limited to,
              TEKA’s official, forum leader, guide or host, or falsely state or
              otherwise misrepresent your affiliation with a person or entity;
            </p>
            <p>
              take any action which encourages or consists of any threat of harm
              of any kind to any person or property;
            </p>
            <p>
              carry out any "denial of service" (DoS, DDoS) or any other harmful
              attacks on application or internet service or;
            </p>
            <p>use the Site/Mobile/Service for illegal purposes;</p>
            <p>
              Disrupt, place unreasonable burdens or excessive loads on,
              interfere with or attempt to make or attempt any unauthorized
              access to any TEKA’s website or mobile apps.
            </p>
            <p>
              transmit through the Site or Mobile apps, any illegal, irritating,
              libelous, damaging, debilitating, hurtful, obscene, disgusting,
              libelous, scornful, or racially, ethnically or something else
              frightful fabric of any kind or nature. This incorporates content,
              illustrations, video, programs or sound, etc.;
            </p>
            <p>
              collect or attempt to gather by and by identifiable data of any
              individual or substance without their express composed consent and
              you should keep up records of any such written consent all through
              the terms of this understanding and for a period of 2 years
              thereafter;
            </p>
            <p>
              engage in antisocial, troublesome, or dangerous acts, counting
              "flaring," "spamming," "flooding," "trolling," and "grieving" as
              those terms are commonly caught on and utilized on the Internet
            </p>
            <p>
              Forge headers or something else control identifiers in arrange to
              mask the beginning of any content transmitted through the Site.
            </p>
            <p>
              upload, post, email, transmit or something else make accessible
              any spontaneous or unapproved publicizing, limited time ma
            </p>
            <p>
              upload, post, email, transmit or otherwise make available any
              unsolicited or unauthorized advertising, promotional materials,
              "junk mail", "spam", "chain letters", "pyramid schemes",
              duplicative messages or any other form of solicitation.
            </p>
            <p>
              encumber or suffer to exist any lien or security interest on the
              subject matter of this Agreement; or Make any representation or
              warranty on behalf of VAPL.
            </p>
            <p>
              You agree not to post, distribute, transmit or otherwise make
              available any data, text, message, computer file, or other
              material that infringes and/or violates any right of a third party
              or any domestic or international law, rule, or regulation,
              including but not limited to:
            </p>
            <p>
              infringement of any copyright, trademark, patent, trade secret, or
              other proprietary rights of any third party, including, but not
              limited to, the unauthorized copying of copyrighted material, the
              digitization and distribution of photographs from magazines,
              books, or other copyrighted sources, and the unauthorized
              transmittal of copyrighted software;
            </p>
            <p>
              right of privacy (specifically, you must not distribute another
              person's personal information of any kind without their express
              permission) or publicity;
            </p>
            <p>
              Any privacy commitment. Unless otherwise permitted, you will: (i)
              show the Service on your Site or Items within the correct shape
              gotten by you, and not adjust or alter any of the previous without
              TEKA's earlier written consent; (ii) ensure that the fundamental
              meaning of the Service isn't changed or mutilated; (iii) comply
              with all pertinent laws and all restrictions and limitations (if
              any) set by TEKA on the use, display or distribution of any
              Service and (iv) not file any of the Service for access by clients
              at any future date after the Service has been removed from your
              Site or item accordingly. You recognize that the benefit must
              interface and divert to the suitable TEKA's web page when a client
              clicks on the Benefit (e.g., a feature). You should not show the
              Benefit in such a way that does not permit for fruitful connecting
              and redirection to, and conveyance of, TEKA's Web page, nor may
              you outline any TEKA's Web page.
            </p>
            <h5>NO CONTROLLING SPAM POLICY OR UNSOLICITED E-MAILS</h5>
            <p>
              You may not utilize any communication instrument or other implies
              accessible on the Site to transmit, specifically or in a
              roundabout way, any spontaneous bulk communications (counting
              emails and instant messages). TEKA will not offer, rent or lease
              its e-mail endorser lists to third parties. You will not gather
              data about clients of TEKA for the reason of sending or to
              encourage the sending of spontaneous bulk communications. We may
              terminate your access or use of the service immediately, with or
              without any notice, and take any other legal action if you, or
              anyone using your access details to the Site or Mobile apps,
              violates these terms. We may adopt any technical remedy (including
              any filtering technology or other measures) to prevent unsolicited
              bulk communications from entering, utilizing or remaining within
              our computer or communication networks. Such sifting technology or
              other measures may block, either briefly or forever, a few e-mails
              sent to you through the TEKA's Web Sites.
            </p>
            <h5>DISCLAIMER OF WARRANTIES AND LIABILITY</h5>
            <p>
              VAPL/TEKA repudiates all guarantees, express or suggested,
              statutory or something else, as to the Services given, counting
              without limitation, the suggested guarantees of merchantability,
              fitness for a particular purpose, workmanlike exertion, title and
              non-infringement are repudiated and avoided.
            </p>
            <p>
              TEKA and its parent, members and partners should not be at risk,
              at any time for any, direct, indirect, punitive, accidental,
              uncommon, consequential, harms (counting, without impediment,
              harms for loss of trade ventures, harm to your computer framework
              or damages for loss of benefits, damages for misfortune of
              utilize, information or benefits, emerging out of or in any way
              associated with the utilize or execution of the TEKA
              sites/mobile/services, with the delay or failure to utilize the
              TEKA's sites/mobile/services or related services, the arrangement
              of or failure to supply services, or for any data, program, items,
              administrations and related design gotten through the TEKA
              sites/mobile/services, or something else emerging out of the
              utilize of the VAPL sites/mobile/services) emerging in contract,
              tort or something else from the utilize of or failure to utilize
              the Location, or any of its contents, or from any act or omissions
              a result of utilizing the Location or any such substance or for
              any disappointment of execution, blunder, omission, interruption,
              deletion, defect, delay in operation or transmission, computer
              virus, communications line failure, theft or destruction or
              unauthorized access to, alteration of, or use of information
              contained on the site. No representations, warranties or
              guarantees whatsoever are made by TEKA as to the (accuracy,
              adequacy, reliability, completeness, suitability or applicability
              of the information to a particular situation; (b) that the service
              will be uninterrupted, timely, secure, or error-free; (c) the
              quality of any products, services, content, information, or other
              material purchased or obtained from the website or mobile apps
              will meet your expectations or requirements; or (d) any blunders
              within the site or portable apps will be rectified.
            </p>
            <h5>CONNECTION TO OTHER SITES</h5>
            <p>
              All the contents of this Site and Mobile apps are as it were for
              common data or utilize. They don't constitute advice and ought to
              not be depended upon in making (or abstaining from making) any
              choice. Any particular advice or answers to inquiries in any
              portion of the Site and Mobile apps is/are the individual opinion
              of such experts/consultants/persons and are not subscribed to by
              this Site or Mobile app. The information from or through this site
              is provided on "AS IS" basis, and all warranties and conditions,
              expressed or implied of any kind, regarding any matter pertaining
              to any goods, service or channel, including without Certain links
              on the Site lead to resources located on servers maintained by
              third parties, these sites of third party(s) may contain
              TEKA's-logo, please understand that it is independent from TEKA,
              over whom TEKA has no control or connection, business or otherwise
              as these sites are external to TEKA. You agree and understand that
              by visiting such sites you are beyond the TEKA's website. TEKA,
              subsequently not one or the other underwrites nor offers any
              judgment or guarantee and acknowledges no obligation or obligation
              for the realness, accessibility, reasonableness, unwavering
              quality, exactness of the data, software, products, services and
              related graphics contained, of any of the goods/Services Web/or
              for any harm, misfortune or hurt, coordinate or significant or any
              infringement of neighborhood or universal laws which will be
              caused by your visit and/or interactions/s on these site(s), as
              the same is given on "as is" without guarantee of any kind. TEKA
              gives no guarantee and makes no representation whether
              communicated or suggested, that the data contained in this
              location is error free. TEKA shall not be responsible nor liable
              for any consequential damages arising on account of your relying
              on the contents of the advertisement. Before relying on the
              material, users should independently verify its relevance for
              their purposes, and should obtain any appropriate professional
              advice.
            </p>
            <h5>CONTENT SUBMITTED BY USERS</h5>
            <p>
              Certain components of the Site will contain material submitted by
              clients. TEKA acknowledges no obligation for the substance,
              precision, similarity to appropriate laws of such fabric. You'll
              reimburse and hold safe TEKA against all third-party claims,
              requests and activities brought against TEKA which emerges from or
              relates to the material submitted by you.
            </p>
            <h5>ADVERTISING MATERIAL/CONTENT</h5>
            <p>
              Portion of the Site contains promoting data or advancement
              material or other material submitted to TEKA by third parties.
              Obligation for guaranteeing that material submitted for
              incorporation on Site or Portable apps complies with appropriate
              Universal and National law is only on the party giving the
              information/material. Your correspondence or trade dealings with,
              or interest in advancements of, promoters other than TEKA found on
              or through the Site and or Mobile apps, counting instalment and
              conveyance of related merchandise or administrations, and any
              other terms, conditions, guarantees or representations related
              with such dealings, are exclusively between You and such
              advertiser. VAPL will not be responsible or liable for any claim,
              error, omission, inaccuracy in advertising material or any loss or
              damage of any sort incurred as the result of any such dealings or
              as the result of the presence of such non-TEKA advertisers on the
              Website and Mobile apps. TEKA reserves the right to omit, suspend
              or change the position of any advertising material submitted for
              insertion. Acknowledgment of notices on the Site and Mobile apps
              will be subject to these terms and conditions.
            </p>
            <h5>PROTECTION</h5>
            <p>
              TEKA may send data and offer products and services to you from
              time to time. For encourage subtle elements relating to our
              arrangement relating to such offer it would be ideal if you allude
              to our privacy statement. Regardless the prior, TEKA reserves the
              right to reveal any data in reaction to / that it is required to
              be shared, unveiled or make made accessible to any administrative,
              authoritative, regulatory or judicial authority beneath any law or
              regulation applicable to TEKA.
            </p>
            <p>
              Further, TEKA can and you authorize TEKA/VAPL to disclose your
              name, street address, city, state, zip code, country, phone
              number, email, and company name to Intellectual Property right's
              owners, as we in our sole discretion believe necessary or
              appropriate in connection with an investigation of fraud,
              intellectual property infringement, piracy, or other unlawful
              activity.
            </p>
            <h5>ASSOCIATION</h5>
            <p>
              None of the provisions of the User Agreement should be regarded to
              constitute an association or agency between you and TEKA and you
              might have no authority to tie TEKA in any way, at all.
            </p>
            <h5>FORCE MAJEURE</h5>
            <p>
              VAPL might have no obligation to you for any intrusion or delay,
              to get to the Site or Mobile apps irrespective of the cause.
            </p>
            <h5>INDIAN LAW</h5>
            <p>
              The Agreement shall be governed by the Laws of India. The Courts
              of law at Hyderabad , Telangana State shall have exclusive
              jurisdiction over any disputes arising under this agreement.
            </p>
            <h5>ENTIRE AGREEMENT</h5>
            <p>
              These Terms of Service constitute the entire agreement between the
              parties with respect to the subject matter in this regard and
              supersedes and replaces all earlier or contemporaneous
              understandings or agreements, written or oral, with respect to
              such subject matter.
            </p>
            <h5>RIGHT TO REMOVE INAPPROPRIATE MESSAGES</h5>
            <p>
              We reserve the right to evacuate any messages we consider to be
              improper, i.e, racist, sexist or debilitating. Messages utilizing
              improper dialect will also be evacuated. We don't wish to be
              censors, but our gatherings must stay civilized and regard the
              rights of others.
            </p>
            <h5>LIMITED TIME TO BRING YOUR CLAIM</h5>
            <p>
              You and TEKA agree that any cause of action arising out of or
              related to the TEKA’s websites or mobile apps, only, must commence
              within one (1) year after the cause of action accrues otherwise,
              such cause of action will be permanently barred.
            </p>
            <h5>ACCEPTANCE POLICY OF PRIVACY</h5>
            <p>
              By using TEKA’s sites and services, you signify your acceptance of
              this Privacy Statement. If you do not agree or are not comfortable
              with any policy described in this Privacy statement, your only
              remedy is to cease the use of TEKA sites or mobile apps. We
              reserve the right, to adjust this Privacy Statement at any time.
            </p>
            <h5>GENERAL TERMS</h5>
            <p>
              Rights and obligations beneath the Terms which by their nature
              ought to survive will stay in full impact after end or close of
              the Terms. Any express waiver or disappointment to work out
              instantly any right under the Terms will not make a proceeding
              waiver or any desire of non-enforcement. If any provision of the
              Terms is held invalid by any law or direction of any government,
              or by any court or authority, the parties concur that such
              arrangement will be supplanted with a new provision that fulfils
              the original business reason, and the other provisions of the
              Terms will stay in full drive and effect.
            </p>
            <p>
              TEKA’s role is limited to managing the web platform for the
              display of the services/offers/deals provided by the restaurants
              or any other product supplier and third parties and other
              incidental services to facilitate, particularly the food
              transactions between the restaurant and the diners.
            </p>
            <p>
              Accordingly, TEKA is merely an intermediary and is only a
              platform/facilitator where the restaurants and other service
              provider may offer its services/deals/offers and discounts for
              marketing purposes. The contract for service shall be a strictly
              bipartite contract between such service provider and the diner. At
              no time shall TEKA have any obligations or liabilities in respect
              of such contract nor shall TEKA hold any rights, title or interest
              in the services provided by such service provider.
            </p>
            <p>
              TEKA might not be dependable for any inadmissible or postponed
              execution or any activities or inactions of the
              vendors/restaurants/third party benefit supplier counting lack in
              services given by such eateries and the benefit suppliers.
            </p>
            <p>
              TEKA may on request of any third party promote its
              products/services by issuing promotional offers/gift
              coupons/discount vouchers/pre-paid vouchers (“promotional
              offers”). It is clarified that such promotional offers received
              from TEKA are to be settled inter-se between the parties to such
              transaction and the respective third party promoting its
              service/product and warranties express or implied of any kind,
              regarding any matter pertaining thereto, including without
              limitation the implied warranties of merchantability, fitness for
              a particular purpose, and non-infringement are disclaimed by
              VAPL/TEKA.
            </p>
            <p>
              Any complaints regarding any matters related to Teka and its
              associates can be sent to complaints can be sent to complaints
              @teka.net.in
            </p>
            <p>
              <b
                >WE ASK YOU TO PLEASE GIVE THE FOLLOWING DATA IN YOUR COMPLAINT:
                -</b
              >
            </p>
            <p>
              A physical or electronic signature of a individual authorized to
              act on sake of the copyright proprietor for the purposes of the
              complaint.
            </p>
            <p>
              Identification of the copyrighted work claimed to have been
              infringed.
            </p>
            <p>
              Identification of the material on our site that's claimed to be
              infringing or to be the subject of infringing activity.
            </p>
            <p>
              The address, telephone number or e-mail address of the complaining
              party.
            </p>
            <p>
              A statement that the complaining party has a good-faith belief
              that use of the material in the manner complained of is not
              authorized by the copyright owner, its agent or the law.
            </p>
            <p>
              A statement, under punishment of prevarication, that the data
              within the take note of copyright infringement is exact, which the
              complaining party is authorized to act on behalf of the proprietor
              of the correct that's supposedly infringed
            </p>
            <h5>TEKA REWARDS PROGRAM AGREEMENT</h5>
            <p>
              For each bolded word or phrase, the definition is provided in the
              definitions section at the end of this Rewards Agreement.
            </p>
            <p>
              Welcome to the Vedhaank Teka Rewards Program for Cash Rewards and
              Point Rewards (together, Rewards).
            </p>
            <p>
              Please read and retain this Rewards Agreement. By participating in
              the Program, you agree to be bound by this Rewards Agreement, and
              the Account Agreement. In the event of a conflict between the
              Account Agreement and this Rewards Agreement, the Account
              Agreement will govern, except this Rewards Agreement will govern
              in any matter relating to the Program. Vedhaank will post any
              changes to this Rewards Agreement on the Website and it is Your
              responsibility to review the Rewards Agreement for any such
              changes.
            </p>
            <p>
              Under the Program, you earn Rewards, either Point Rewards or Cash
              Rewards depending on Your Account product, every time You make a
              Qualifying Purchase. You can use Your Rewards to redeem for
              Rewards Offerings. Point Rewards and Cash Rewards can be combined
              to redeem for Rewards Offerings. The complete selection of Rewards
              Offerings is available on the Website and on your app.
            </p>
            <h2>Definitions</h2>
            <h5>Rewards Service Center and Website.</h5>
            <p>
              The Rewards Service Center phone number is +91 6305920774 and is
              available Monday to Friday from 7:00 a.m. - 7:00 p.m., ET. This
              number provides full-service customer support for Rewards
              including redemption of Rewards Offerings for statement credits,
              gift cards, merchandise, experiential rewards and travel,
              including airline, hotels and car rentals. The Website address is
              www.teka.net.in
            </p>
            <h5>Eligibility.</h5>
            <p>
              To participate in the program, you must be above 18. Your Account
              must be and remain in Good Standing. Vedhaank reserves the right
              to determine in Vedhaank sole discretion whether you are eligible
              for enrollment or continued participation in the Program. Use of
              the Program may be restricted for certain types of organizations
              including, but not limited to, tax-exempt entities and trusts.
              Please consult Your tax and legal advisors to determine if such
              restrictions apply to Your organization.
            </p>
            <h5>Enrollment.</h5>
            <p>
              Your Account is automatically enrolled in the Program. Once you
              are enrolled in the Program/download the app, you can access Your
              Program information via the Website or by app.
            </p>
            <h5>Rewards and Redemption Overview.</h5>
            <p>
              Vedhaank will not be liable for fulfilling Reward Offering
              requests that Vedhaank believes in good faith are made by any
              person claiming the authority to act on Your behalf. All Rewards
              Offerings are subject to availability. Vedhaank reserves the right
              to substitute Rewards Offerings of equal or greater value.
            </p>
            <h5>Rewards Earning Limitations.</h5>
            <p>
              Your Rewards Account balance will be reduced by (a) any returns or
              credits, (b) any Qualifying Purchase which becomes the subject of
              a chargeback or other dispute unless and until final resolution of
              the dispute results in a debit to Your account, whenever there is
              cancellation of order or purchases, the company will have to take
              back allotted redeemable points made through either from your
              personal account or from your community purchases. If Your Rewards
              Account is reduced by negative Rewards and You received a
              redemption of a Rewards Offering that, based upon such a
              reduction, You were not entitled to receive, Vedhaank has the
              right to withhold the applicable amount of Your subsequent Cash
              Rewards or Point Rewards earned in order to cover the correct
              amount of such Rewards redemption.
            </p>
            <p>
              Except for the cash back redemption options described below,
              Rewards have no cash value and may only be used to obtain Rewards
              Offerings as described in this Rewards Agreement. Rewards may not
              be combined with other discounts, special rates, promotions, or
              other reward programs offered by Vedhaank or any third party,
              including airline frequent flier, or other travel-related or
              membership reward charge or credit card programs, unless
              specifically authorized by Vedhaank. Rewards cannot be
              transferred, gifted, sold, attached, pledged or bartered under any
              circumstance, including disability, death, upon operation of law
              or in connection with a domestic relations or other legal dispute.
              Vedhaank may suspend redemption rights of Your Rewards if there is
              a dispute between You and Vedhaank or between You and an
              authorized user or joint owner with respect to the account.
              Vedhaank is not responsible for any disputes You may have with an
              authorized user on your account about the Program or the Website.
              Neither Cash Rewards nor Point Rewards may be applied to Your
              Account statement to produce a credit balance on Your Account.
            </p>
            <p>
              You can receive information regarding Your Rewards Account
              (including the number of Rewards earned, redeemed, scheduled to
              expire, or expired/forfeited, if applicable) by going to the
              Website or calling the Rewards Service Center. You can view your
              rewards balance at www.teka.net.in or by using the Vedhaank/Teka
              Mobile App.
            </p>
            <h5>Forfeiture of Rewards.</h5>
            <p>
              Rewards will be forfeited if (1) You file for bankruptcy, or (2)
              If you do not purchase a minimum of Rs. 1500 every month otherwise
              points will flush out.
            </p>
            <h5>Earning Cash Rewards.</h5>
            <p>
              As soon as your referral downloads you tap through referral ID
              makes their first purchase a minimum of Rs 50/-, you will get
              promotional Cashback based upon the percentage allotted to the
              particular products, these points are deducted from Startup
              points, Welcome Points, Group Welcome points. If the Customer do
              not possess Start up Points, Welcome points or Group welcome
              points, Promotional cashback is not awarded.
            </p>
            <h5>Merchandise, Gift Certificate and Gift Card Rewards</h5>
            <p>
              You may redeem Rewards for merchandise and gift cards/certificates
              from the Website or with the assistance of the Rewards Service
              Center. Rewards Offerings are subject to availability. Vedhaank
              may change the Rewards Offerings selection and the number of Point
              Rewards or Cash Rewards needed to obtain the Rewards Offerings at
              any time. Merchandise Rewards Offerings are offered and provided
              by independent manufacturers and include applicable sales tax and
              shipping and handling costs (via first-class mail, ground delivery
              or freight service across the region the company is operating.).
              Vedhaank and have no responsibility or liability for such
              products.
            </p>
            <p>
              All merchandise orders are subject to availability, and Vedhaank
              reserves the right to substitute merchandise of equal or greater
              value. The merchandise shown on the Website may not reflect the
              exact colors or model numbers of the actual Rewards Offerings due
              to manufacturers’ model or style updates, or the photo facsimile
              used as a representation of the merchandise. Purchase protection
              or extended warranty coverage associated with Your Account does
              not apply to merchandise Rewards Offerings.
            </p>
            <p>
              Rewards Offerings are shipped prepaid and cannot be returned or
              exchanged unless the merchandise arrived damaged, defective or if
              the wrong item was shipped. Returns and exchanges will only be
              accepted within 30 days after Your receipt of the merchandise. You
              should call the Rewards Service Center for return/exchange
              authorization and assistance. The Rewards You used to redeem
              merchandise Rewards Offerings will be credited back to Your
              Rewards Account if You return the Rewards Offerings in a timely
              fashion and in accordance with the procedures described above.
            </p>
            <p>
              Gift cards are not exchangeable, refundable, or redeemable for
              cash or credit under any circumstances, and are not valid on
              previous purchases. Lost or stolen gift cards cannot be replaced.
              Vedhaank is not responsible for gift cards lost or stolen while in
              transit to You.
            </p>
            <p>
              Merchandise, gift cards and gift certificates Rewards Offerings
              can be shipped to any address that You designate so long as it is
              a valid street address within the region operated by company. All
              claims regarding non-receipt of redeemed Rewards Offerings must be
              reported to the Rewards Service Center within 90 days of
              redemption.
            </p>
            <p>
              You will find complete details about shipping, including
              information about direct shipments from suppliers, large freight
              items, damaged or incomplete shipments, and details about
              exchanges and refunds on the Website or You can call the Rewards
              Service Center.
            </p>
            <p>
              All brand names are the trademarks and property of their
              respective owners and are used with permission.
            </p>
            <h5>Travel Rewards Offerings.</h5>
            <p>
              You will have the opportunity to redeem Rewards for travel
              including air, hotel, cruises, as and when company forms a
              partnership deals with them. Cash Rewards or Point Rewards
              required for travel Rewards Offerings will include taxes,
              destination fees, fuel charges, and any other airline surcharges.
              Other restrictions and limitations may apply. Once Rewards
              redemptions for travel Rewards Offerings are complete, the
              transaction cannot be reversed.
            </p>
            <p>
              You may elect to execute redemptions for Travel Rewards Offerings
              using the Rewards Service Center; this service will be subject to
              a service fee of Rs.1500 at time of booking. Rewards redemptions
              for Travel Rewards Offerings executed on the Website will not be
              subject to any booking service fees.
            </p>
            <p>
              The traveler is responsible for obtaining the appropriate
              international travel documentation, such as passports and visas.
              Vedhaank assume no responsibility for advising guests of proper
              travel documentation.
            </p>
            <h5>Air travel</h5>
            <p>
              There are no blackout dates. Travel is subject to availability.
              Participating air carriers are subject to change. All airline
              rules and restrictions apply (check requirements when booking).
              Tickets may be purchased in any individual’s name.
            </p>
            <p>
              Miscellaneous costs, including baggage, airport/airline fees and
              surcharges, government-imposed fees, gratuities, insurance and
              airline amenities are your responsibility. Your tickets will be
              non-refundable and non- transferable.
            </p>
            <p>
              All returns, exchanges and cancellations should be handled
              directly with the airline. The airline may charge fees plus the
              difference in airfare for any changes.
            </p>
            <p>
              All tickets are issued as electronic tickets. All tickets must be
              issued at the time of booking and reservations will not be held.
              Before completing Your order, please confirm that all information
              is accurate. All airline redemptions are non-refundable and
              non-transferable.
            </p>
            <p>
              All travel itineraries and supporting documentation will be sent
              via email. Reservations for tickets also exclude the use of
              charters, wholesalers, consolidators, and any Internet fares that
              are not published, not available through the Rewards Service
              Center, and/or not available for ticketing through a certified
              travel agency.
            </p>
            <p>
              Once Rewards are redeemed, the transaction cannot be reversed. If
              changes to an itinerary are necessary later, You or another
              authorized participant may contact the Rewards Service Center with
              the request up to 5 days prior to the travel date. Based on
              airline requirements, changes may require additional costs such as
              airline penalty fees, increased fares and service fees. Most
              airlines will not allow traveler name changes.
            </p>
            <p>
              Flight reservations should be re-confirmed by the traveler at
              least 72 hours prior to departure. All reservations are subject to
              the rules, restrictions and conditions of the service provider,
              which include exclusions and limitations of liability.
            </p>
            <p>
              The traveler should have valid government-issued photo ID upon
              airport check-in.
            </p>
            <p>
              Airline ticket awards may not be used in conjunction with any type
              of coupons, vouchers, other mileage programs or companion fares.
            </p>
            <p>
              Vedhaank is not responsible for communication of airline schedule
              changes.
            </p>
            <h5>Hotel Stays</h5>
            <p>
              You may redeem Point Rewards or Cash Rewards for any hotel
              connected to Teka/Vedhaank across the regions operating. Hotels
              may only be booked through the Rewards Service Center. You must
              meet the eligibility requirements established by the hotel
              provider.
            </p>
            <p>
              Most hotel rates allow cancellation with a minimum of a 24-hour
              notice. Please see the specific hotel/rate cancellation policy at
              the time of booking. Cancellations may include penalties and a
              cancellation service fee will apply. Refunds for cancellations may
              take up to 3 to 4 weeks.
            </p>
            <p>
              Hotels do not allow changes to dates, names, room type and number
              of occupants once booking is complete.
            </p>
            <p>
              Vedhaank is not responsible for the performance of any hotel
              provider.
            </p>
            <h5>Cruises</h5>
            <p>
              Cruise packages may only be booked through the Rewards Service
              Center.
            </p>
            <p>
              All cruise redemption requests must be made at least 30 days prior
              to sailing date or You may incur additional fees.
            </p>
            <p>
              All cruise Rewards Offerings are based on double occupancy for a
              cabin. Traveler must meet the eligibility requirements established
              by the cruise provider.
            </p>
            <p>
              Participating cruise lines are subject to change at any time
              without notice.
            </p>
            <p>
              Changes may be made up to 90 days prior to sailing (120 days for
              holiday and special event cruises) for a Rs.6500 change fee, plus
              any fees imposed by the cruise line. Changes under 90 days (120
              days for holiday and special event cruises) may result in
              forfeiture of the Rewards, or additional fees may apply. In
              addition, if the cardholder or recipient is a no-show, the travel
              Rewards redemption is void.
            </p>
            <p>
              Cruise packages that have been booked may not be cancelled and are
              not eligible for any refund in part or whole. No interim price
              reductions will be considered or offered once the booking has been
              completed.
            </p>
            <p>
              Vedhaank is not responsible for the performance of the cruise
              line.
            </p>
            <h5>Experiential Rewards</h5>
            <p>
              You will now have the opportunity to redeem Cash Rewards or Point
              Rewards for a variety of experiential Rewards Offerings from the
              Website or the Rewards Service Center. Rewards Offerings are
              subject to availability.
            </p>
            <p>
              Vedhaank may change the Rewards Offerings selection and the number
              of Cash Rewards or Point Rewards needed to obtain the Rewards
              Offerings at any time.
            </p>
            <p>
              Experiential Rewards may only be booked through the Rewards
              Service Center.
            </p>
            <h5>Customer Service/Error Resolution.</h5>
            <p>
              If You have any questions regarding the number of Rewards in Your
              Rewards Account, whether Rewards were earned from a particular
              Qualifying Purchase, the status of Reward Offering redemption or
              any other question, call the Rewards Service Center at +91
              6305920774 If You believe there has been an error, you must report
              such error to Vedhaank promptly and always within 20 days after
              the date the Qualifying Purchase or any subsequent adjustment is
              posted to Your Rewards Account. Vedhaank is not liable for any
              error after that time period. After that time period, Your Rewards
              Account will be deemed accurate. Rewards Account errors are not
              billing errors under Your Account or Account Agreement. Vedhaank
              decision about any error will be final.
            </p>
            <h5>Taxes.</h5>
            <p>
              Earning Rewards and redemption for Rewards Offerings may be
              subject to income and/or other taxes. Any applicable state or
              local tax obligations related to Your participation in the Program
              are Your responsibility. Please consult Your tax advisor
              concerning any such income or other tax consequences.
            </p>
            <h5>Audits/Disqualification.</h5>
            <p>
              Vedhaank reserves the right to audit Your Account for compliance
              with this Rewards Agreement. In the event an audit reveals any
              point or other discrepancies, the awarding of Rewards and the
              redemption for Rewards Offerings may be delayed until such
              discrepancies are resolved. Vedhaank reserves the right to
              disqualify You from participation in the Program and close Your
              Account if, in Vedhaank sole judgment, Your Account is not in Good
              Standing, You have provided false information, or You have
              violated any of the terms and conditions of this Rewards Agreement
              or the Program, including but not limited to fraud. Such
              disqualification may be temporary or permanent and may result in
              the forfeiture of any accumulated Rewards. Vedhaank reserves the
              sole discretion to interpret and apply the policies and procedures
              communicated in this Rewards Agreement. All determinations by
              Vedhaank will be final.
            </p>
            <h5>Changes.</h5>
            <p>
              Vedhaank may, from time to time and in Vedhaank sole discretion,
              amend, delete or add to the terms of this Rewards Agreement and
              may change or limit any aspect of the Program and its
              restrictions, benefits or features, in whole or in part. Such
              changes may be retroactive as allowed by applicable law. Changes
              may include, but are not limited to, the number of Rewards
              required to earn Rewards Offerings, the type of transactions which
              qualify for Rewards, the type and/or value of Rewards Offerings,
              the availability of Rewards Offerings, as the programmed is free
              right now, if at all there is an inclusion of any fee, or the
              number of Rewards that may be earned or purchased. Your
              accumulation of Rewards does not give You any vested rights and
              You may not rely upon the continued availability of any Rewards
              Offerings. Vedhaank will post any such changes to the Rewards
              Agreement on the Website and it is Your responsibility to review
              the Rewards Agreement for any such changes.
            </p>
            <h5>Termination.</h5>
            <p>
              Vedhaank reserves the right to suspend or terminate the Program
              and this Rewards Agreement at any time. Vedhaank decisions
              regarding the Program are final. If the Program is terminated, You
              will be notified of the date and all the rewards points stand
              forfeited.
            </p>
            <h5>Other Important Information</h5>
            <p>Incorporation of Account Agreement.</p>
            <p>
              The terms of Your Account Agreement are incorporated into this
              Rewards Agreement for purposes of governing law, enforcement
              rights and dispute resolution, including the arbitration provision
              contained therein. Please read your Account Agreement for these
              important provisions.
            </p>
            <h5>Governing Law.</h5>
            <p>
              This Rewards Agreement will be governed by and construed in
              accordance with the laws of the State of Telangana District,
              without reference to conflict of laws principles. Where the
              arbitration provisions of this Rewards Agreement or Your Account
              Agreement, whichever governs, are inapplicable, and except for
              actions for injunctive relief, any legal action brought under or
              in conjunction with this Rewards Agreement must be brought in a
              state court of appropriate jurisdiction in the State of Telangana
              District and venue will be proper in that court.
            </p>
            <h5>Third Party Providers.</h5>
            <p>
              The merchants, vendors and third-party service providers that
              participate in the Program, including those that provide Rewards
              Offerings, are not affiliated with Vedhaank, and are not sponsors
              or cosponsors of the Program. All participating merchant and
              third-party service provider names, logos, and marks are used with
              permission and are the property of their respective owners.
              Participating merchants and third-party service providers are
              subject to change without notice.
            </p>
            <p>
              Participating merchants and third-party service providers are
              responsible for the quality and performance of any products or
              services they provide or offer as a Rewards Offering. Vedhaank is
              not responsible for any aspects of the products or services
              offered as Rewards Offerings.
            </p>
            <h5>Disclaimer of Liability.</h5>
            <p>
              Vedhaank and its third-party service providers, their respective
              affiliates, directors, officers, employees, agents, or contractors
              make no representations or warranties, either express or implied,
              including those of merchantability, fitness, intended use or a
              particular purpose and otherwise arising by law, custom, usage,
              trade practice, course of dealing or course of performance. You
              release Vedhaank and its third party service providers, and their
              respective affiliates, directors, officers, employees, agents, or
              contractors for all activity in connection with the Program or the
              Website, including but not limited to use of the Program or
              Website, and any redemption or attempted redemption for Rewards
              Offerings through the Program or Website.
            </p>
            <h5>Indemnification.</h5>
            <p>
              You agree to indemnify Vedhaank and its third-party service
              providers and their respective affiliates, directors, officers,
              employees, agents or contractors harmless from and against any
              loss, damage, liability, cost or expense of any kind (including
              attorneys’ fees) arising from You or an authorized user’s use of
              the Program or Website, any fraud or misuse of the Program or
              Website, violation of this Rewards Agreement, or violation of any
              applicable law or the rights of any third party.
            </p>
            <h5>Assignment.</h5>
            <p>
              Vedhaank may assign its rights under this Rewards Agreement to a
              third party, who will then be entitled to any rights that we
              assign to them. You may not assign or transfer any right or
              obligation under this Rewards Agreement without Vedhaank prior
              written consent; any purported assignment violating the foregoing
              will be null and void.
            </p>
            <h5>Unenforceability.</h5>
            <p>
              If any provision of this Rewards Agreement is found by a court of
              competent jurisdiction or an arbitrator to be unenforceable or
              invalid, the unenforceability or invalidity will not render this
              Rewards Agreement unenforceable or invalid as a whole; rather,
              this Rewards Agreement will be construed as if not containing the
              particular invalid or unenforceable provision or portion thereof,
              and the rights and obligations of You or Vedhaank will be
              construed and enforced accordingly. In that event, You agree to
              negotiate in good faith a replacement provision that would best
              accomplish the objectives of the unenforceable or invalid
              provision within the limits of applicable law.
            </p>
            <h5>Jury Trial Waiver.</h5>
            <p>
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, FOR ANY MATTERS NOT
              SUBMITTED TO ARBITRATION, YOU AND VEDHAANK HEREBY KNOWINGLY,
              VOLUNTARILY, INTENTIONALLY AND IRREVOCABLY WAIVE THE RIGHT TO A
              TRIAL BY JURY WITH RESPECT TO ANY LITIGATION ARISING OUT OF THIS
              REWARDS AGREEMENT OR ANY OTHER DISPUTE OR CONTROVERSY BETWEEN YOU
              AND VEDHAANK.
            </p>
            <h5>Class Action Waiver.</h5>
            <p>
              TO THE EXTENT PERMITTED BY APPLICABLE LAW, FOR ANY MATTERS NOT
              SUBMITTED TO ARBITRATION, YOU AND VEDHAANK HEREBY AGREE THAT ANY
              LITIGATION ARISING OUT OF THIS REWARDS AGREEMENT, RELATING TO THE
              PROGRAM OR WEBSITE, OR ANY OTHER DISPUTE OR CONTROVERSY BETWEEN
              YOU AND VEDHAANK WILL PROCEED ON AN INDIVIDUAL BASIS AND WILL NOT
              PROCEED AS PART OF A CLASS ACTION AND THAT YOU AND VEDHAANK HEREBY
              KNOWINGLY, VOLUNTARILY, INTENTIONALLY AND IRREVOCABLY WAIVE ANY
              RIGHT TO PROCEED IN A CLASS ACTION OR TO SERVE AS A CLASS
              REPRESENTATIVE.
            </p>
            <h5>Entire Agreement.</h5>
            <p>
              This Rewards Agreement, as modified from time to time, constitutes
              the entire Rewards Agreement between You and Vedhaank, and
              supersedes any prior negotiation or understanding between You and
              Vedhaank concerning the subject matter of this Rewards Agreement.
              Section headings are for convenience only and are to be
              disregarded when interpreting this Rewards Agreement. Vedhaank may
              enforce the terms of this Rewards Agreement at any time and may
              delay enforcement without losing its right to enforce the Rewards
              Agreement at a later time. No course of dealing or usage of trade
              will be used to modify the terms of the Rewards Agreement.
            </p>
            <h5>Definitions.</h5>
            <p>
              <b>Business Accounts -</b> means Vedhaank Credit Accounts that are
              issued in the name of the business and governed by the Vedhaank
              Business Account Agreement, including, Vedhaank World for Business
              and Vedhaank Specialty Business Accounts.
            </p>
            <p><b>Account(s) -</b> means Your Vedhaank Credit Account.</p>
            <p>
              <b>Account Agreement -</b> means the Credit account agreement
              between You and Vedhaank that provides the terms of Your Account,
              and any modifications, amendments or changes made by Vedhaank to
              that Agreement.
            </p>
            <p>
              <b>Cash Deposit Bonus -</b> The bonus amount will be determined by
              the cardholder’s deposit relationship with Vedhaank Bank at the
              time Cash Rewards are redeemed and consistent with the redemption
              procedures described in this agreement.
            </p>
            <p>
              <b>Cash Rewards -</b> means if you are enrolled in the Cash
              Rewards Program, the Rewards earned through Qualifying Purchases
              on Your Account will be cash that you earn through the Program and
              which you may use to obtain Rewards Offerings, including cash
              back, merchandise, gift cards and Travel, in accordance with the
              Rewards Agreement.
            </p>
            <p>
              <b>Consumer Account(s) -</b> Web and mobile app participant
              accounts.
            </p>
            <p>
              <b>Good Standing -</b> means that Your Account is not in default
              and that You are in full compliance with the provisions of Your
              Account Agreement.
            </p>
            <p>
              <b>Point Rewards -</b> means that, if Your Account is enrolled in
              the Points Rewards Program, the points that you earn via the
              Program and which you may use to obtain Rewards Offerings,
              including cash back, merchandise, gift cards and Travel.
            </p>
            <p>
              <b>Rewards -</b> means the Point Rewards, Cash Rewards or both,
              that You earn by making Qualifying Purchases on Your Account in
              accordance with the Program.
            </p>
            <p>
              <b>Rewards Account(s) -</b> means the account in which Your Point
              Rewards or Cash Rewards are placed and maintained for Your use in
              accordance with this Rewards Agreement. Rewards Agreement – means
              this agreement, which contains the terms and conditions and other
              important information about Vedhaank Rewards Program.
            </p>
            <p>
              <b>Rewards Offerings -</b> means the cash back, merchandise, gift
              card, travel and other goods and services offered for redemption
              through the Program.
            </p>
            <p>
              <b>Rewards Service Center -</b> means the call center managed by
              Vedhaank which You may call to access full-service support for
              Rewards, including inquiries about Your Rewards Account and
              redemption of Rewards Offerings.
            </p>
            <p>Website – www.teka.net.in</p>
            <p>Copyright Notice</p>
            <p>
              Copyright © 2022, TEKA.net.in (Vedhaank Digital Pvt Ltd). All
              Rights Reserved.
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- Section ends -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      errors: [],
      selected: "ENGLISH",
      options: [
        { value: "ENGLISH", text: "English" },
        { value: "BANGLA", text: "Bangla" },
        { value: "TELAGU", text: "Telagu" },
      ],
    };
  },
  created() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>
<style scoped>
.policy-content .eb-useing-gumtree {
  position: relative;
}
.policy-content h3 {
  color: #533333;
  font-weight: 600;
}
.policy-content .eb-device-terms li {
  font-size: 16px;
  font-weight: 600;
  color: #212121;
  position: relative;
  margin-bottom: 10px;
  display: list-item;
  list-style: circle;
  margin-left: 1.4%;
}
.policy-content .eb-useing-gumtree li {
  font-size: 14px;
  font-weight: 600;
  color: #212121;
  position: relative;
  padding-left: 35px;
  margin-bottom: 10px;
  display: list-item;
  list-style: none;
}
.policy-content .eb-useing-gumtree li i {
  position: absolute;
  top: 3px;
  left: 0;
  color: #828282;
  font-size: 14px;
  background-color: #d7eef8;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
}
.flaticon-checked:before {
  content: "✓";
}
</style>
